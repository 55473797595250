var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    {
      staticClass: "d-flex align-content-center eth-block-block-loading",
      attrs: { color: "transparent", "min-height": "148" },
    },
    [
      _c("v-progress-circular", {
        staticClass: "ma-auto",
        attrs: { indeterminate: "", color: "primary" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }